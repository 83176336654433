<template>
  <div class="artical-card bg-white rounded-4 shadow p-lg-5 pt-lg-3">
    <div class="header-artical cursor-pointer border-bottom">
      <div class="d-flex flex-lg-row flex-column justify-content-between align-items-lg-center align-items-start mb-2 mb-lg-0">
        <div class="align-items-center w-100">
          <div class="fs-7 d-flex mb-1rem block-time pulsate"></div>
          <h3 class="fw-bold mb-4 mb-1rem fs-9 ps-lg-0 text-break block-title pulsate"></h3>
        </div>
      </div>
    </div>
    <div>
      <div class="mt-3 text-break artical-content">
        <div class="block-content pulsate"></div>
        <div class="block-content pulsate"></div>
        <div class="block-content pulsate"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.artical-card {
  padding: 1rem 1rem 2rem 1rem !important;
}

.header-artical {
  padding-bottom: 0.5rem !important;
}

.artical-content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5em;
  height: 4.5em;
  max-height: 4.5em;
  min-height: 4.5em;
}

.mb-1rem {
  margin-bottom: 1rem !important;
}

.block-time {
  width: 78px;
  height: 12px;
  margin-bottom: 8px;
}

.block-title {
  width: 60%;
  height: 12px;
  margin-bottom: 8px;
}

.block-content {
  width: 100%;
  height: 12px;
  margin-bottom: 8px;
}

.pulsate {
  background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;
}

.circle-avatar {
  width: 70px;
  height: 70px;
  object-fit: cover;

  @include media_mobile() {
    width: 40px;
    height: 40px;
  }
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
